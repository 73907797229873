<template>
    <div class='container'>
        <div class="row mt-5 justify-content-center">
            <div class="col col-md-6">
                <div class="card bg-light shadow-sm ">
                    <div class="card-header text-dark fw-bold border-warning">
                        PAINEL
                    </div>
                    <div class="card-body border-warning">
                        <h4 class='text-center'>
                            Gerenciamento de dados exibidos no SITE ENC
                        </h4>
                    </div>
                    <div class="card-footer border-warning">
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-warning btn-block border-dark" @click="$router.push('/enc-painel/taxonomia')">
                                    Taxonomia
                                </button>
                            </div>
                            <div class="col">
                                <button class="btn btn-warning btn-block border-dark" @click="$router.push('/enc-painel/job')">
                                    JOBs
                                </button>
                            </div>
                            <div class="col">
                                <button class="btn btn-warning btn-block border-dark" @click="$router.push('/enc-painel/imagens')">
                                    Imagens
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
    data() {
        return {
           
        }
    },
    methods: {
       
    },
    created() {
    },
    mounted(){
        
    }

}
</script>

<style scoped>

.btn-block{
    width: 100%;
}
.bg-card{
    background-color: #f9c8276b !important;
}

</style>

